import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PngIcon } from '../icons/png-icon.svg'; // Adjust paths as needed
import { ReactComponent as JpegIcon } from '../icons/jpg-icon.svg';
import { ReactComponent as Remove } from '../icons/trash.svg';
import { ReactComponent as PdfIcon } from '../icons/pdf-icon.svg';
import '../styles/uploadFile.css';

const UploadFile = ({ file, onRemove, uploadProgress }) => {
  const getFileTypeIcon = () => {
    const fileExtension = file.name.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'png':
        return <PngIcon className="file-icon"/>;
      case 'jpeg':
      case 'jpg':
        return <JpegIcon className="file-icon"/>;

      case 'pdf':
        return <PdfIcon className="file-icon"/>;
      default:
        return null;
    }
  };

  const formatFileSize = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
    return `${(size / 1048576).toFixed(2)} MB`;
  };

  return (
    <div className="file-item">
      <div>{getFileTypeIcon()}</div>
      <div className="file-details">
        <span className="file-name">{file.name}</span>
        <span className="file-size">{formatFileSize(file.size)}</span>
      </div>
      <button className="remove-file-button" onClick={() => onRemove(file)}>
        <Remove className="icon"/>
      </button>
    </div>
  );
};

UploadFile.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  uploadProgress: PropTypes.number.isRequired,
};

export default UploadFile;
