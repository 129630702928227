import React, { useState } from "react";
import "../styles/translationHandler.css";

const TranslationHandler = ({ data, onTranslationComplete }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [isTranslating, setIsTranslating] = useState(false);

  const BASE_URL = "https://us-central1-vaccine-genie.cloudfunctions.net";

  const translateText = async (text, targetLanguage) => {
    try {
      const response = await fetch(`${BASE_URL}/translate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text, targetLanguage }),
      });

      const data = await response.json();
      return data.translatedText || text; // Fallback to original text
    } catch (error) {
      console.error("Translation error:", error);
      return text;
    }
  };

  const translateData = async (data, targetLanguage) => {
    return await Promise.all(
      data.map(async (row) => {
        const translatedRow = { ...row };
        translatedRow.vaccineName = await translateText(row.vaccineName, targetLanguage);
  
        if (row.vaccinationEvents) {
          translatedRow.vaccinationEvents = await Promise.all(
            row.vaccinationEvents.map(async (event) => {
              const translatedEvent = { ...event };
              for (const [key, value] of Object.entries(event)) {
                // Skip translation for the date field
                if (key !== "date" && typeof value === "string") {
                  translatedEvent[key] = await translateText(value, targetLanguage);
                }
              }
              return translatedEvent;
            })
          );
        }
  
        return translatedRow;
      })
    );
  };

  const translateHeaders = async (targetLanguage) => {
    const headers = {
      vaccineName: "Vaccine Name",
      vaccinationDate: "Vaccination Date(s)",
      batchNumber: "Vaccine Batch/Lot #",
      site: "Site of Injection",
      adminName: "Administrator",
      addAnotherDate: "Add Another Date",
      removeVaccine: "Remove Vaccine",
      addNewVaccine: "Add New Vaccine"
    };

    const translatedHeaders = {};
    for (const [key, value] of Object.entries(headers)) {
      translatedHeaders[key] = await translateText(value, targetLanguage);
    }
    return translatedHeaders;
  };

  const handleLanguageChange = async (event) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);

    if (data && newLanguage) {
      setIsTranslating(true);
      try {
        const translatedData = await translateData(data, newLanguage);
        const translatedHeaders = await translateHeaders(newLanguage);
        onTranslationComplete(translatedData, translatedHeaders);
      } catch (error) {
        console.error("Failed to translate:", error);
      } finally {
        setIsTranslating(false);
      }
    }
  };

  return (
    <div className="translation-container">
    <label htmlFor="language-selector" className="language-label">Select a Language:</label>
    <select
        id="language-selector"
        className="language-dropdown"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        disabled={isTranslating}
    >
        <option value="" disabled>
        Language Options
        </option>
        <option value="en">English</option>
        <option value="es">Spanish</option>
        <option value="fr">French</option>
        <option value="zh">Chinese</option>
    </select>
    {isTranslating && <p className="translation-status">Translating...</p>}
    </div>

  );
};

export default TranslationHandler;
