import React, { useState, useEffect } from "react";
import "../styles/editableTable.css"
import { ReactComponent as Remove } from '../icons/trash.svg';
import TranslationHandler from "./translationHandler.js";
import DownloadButton from "./downloadButton.js";

// ISSUE: translation does not affect added info like injection site and adminstrator
// need to have all data translated when passed to translation api
// still need to translate the headings as well with the selector


const convertToISOFormat = (dateStr) => {
  if (!dateStr) return "";
  const parts = dateStr.split("/");
  if (parts.length !== 3) return dateStr; // Return original if not in expected format
  let [month, day, year] = parts;
  if (year.length === 2) year = "20" + year; // Assume 20xx for two-digit years
  return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const convertToMMDDYYYYFormat = (isoDateStr) => {
  if (!isoDateStr) return "";
  const [year, month, day] = isoDateStr.split("-");
  return `${month}/${day}/${year}`;
};



const EditableTable = ({ ocrData }) => {
  const [tableData, setTableData] = useState([]);
  const [translatedHeaders, setTranslatedHeaders] = useState({
    vaccineName: "Vaccine Name",
    vaccinationDate: "Vaccination Date(s)",
    batchNumber: "Vaccine Batch/Lot #",
    site: "Site of Injection",
    adminName: "Administrator",
    addAnotherDate: "Add Another Date",
    removeVaccine: "Remove Vaccine",
    addNewVaccine: "Add New Vaccine"

  });

  useEffect(() => {
    // Check if ocrData is valid
    if (!ocrData) {
      console.error("ocrData is null or undefined");
      return;
    }
    if (typeof ocrData === "string") {
      try {
        ocrData = JSON.parse(ocrData); // Parse if it's a string
        console.log("ocrData parsed from string:", ocrData);
      } catch (error) {
        console.error("Error parsing ocrData:", error);
        return;
      }
    }
    if (!Array.isArray(ocrData)) {
      console.error("ocrData is not an array:", ocrData);
      return;
    }


    // Validate and transform ocrData
    const updatedData = ocrData.map((vaccine, index) => {

      // Validate vaccine structure
      if (!vaccine || typeof vaccine !== 'object') {
        console.error(`Invalid vaccine object at index ${index}:`, vaccine);
        return {
          vaccineName: "",
          vaccinationEvents: [],
        };
      }

      const vaccineName = vaccine.vaccineName || "";
      const vaccinationEvents = Array.isArray(vaccine.vaccinationEvents)
        ? vaccine.vaccinationEvents.map((event, eventIndex) => {

            // Validate event structure
            if (!event || typeof event !== 'object') {
              console.error(`Invalid vaccination event at index ${eventIndex}:`, event);
              return { date: "", batchNumber: "", site: "", adminName: "" }; // Return empty event
            }

            return {
              date: event.date ? convertToISOFormat(event.date) : "", // Handle null date
              batchNumber: event.batchNumber || "",
              site: event.site || "",
              adminName: event.adminName || "",
            };
          })
        : [{ date: "", batchNumber: "", site: "", adminName: "" }]; // Default if no events

      return {
        vaccineName,
        vaccinationEvents,
      };
    });

    setTableData(updatedData);
  }, [ocrData]);

  const handleTranslationComplete = (translatedData, headers) => {
    setTableData(translatedData); // Update the table with translated data
    setTranslatedHeaders(headers);
  };
  
  const handleChange = (e, rowIndex, field, eventIndex = null) => {
    const updatedTable = [...tableData];
  
    if (eventIndex !== null) {
      // For date fields, keep ISO format for compatibility with <input type="date">
      updatedTable[rowIndex].vaccinationEvents[eventIndex][field] = 
        field === "date" ? e.target.value : e.target.value;
    } else {
      updatedTable[rowIndex][field] = e.target.value;
    }
  
    setTableData(updatedTable);
  };
  

  const addVaccinationEvent = (rowIndex) => {
    const updatedTable = [...tableData];
    updatedTable[rowIndex].vaccinationEvents.push({ date: "", batchNumber: "", site: "", adminName: "" });
    setTableData(updatedTable);
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      { vaccineName: "", vaccinationEvents: [{ date: "", batchNumber: "", site: "", adminName: "" }] }
    ]);
  };

  const removeVaccinationEvent = (rowIndex, eventIndex) => {
    const updatedTable = [...tableData];
    updatedTable[rowIndex].vaccinationEvents.splice(eventIndex, 1); // Remove the specific event
    if (updatedTable[rowIndex].vaccinationEvents.length === 0) {
      updatedTable.splice(rowIndex, 1); // Remove the vaccine if no events remain
    }
    setTableData(updatedTable);
  };
  
  const removeVaccine = (rowIndex) => {
    const updatedTable = [...tableData];
    updatedTable.splice(rowIndex, 1); // Remove the entire vaccine
    setTableData(updatedTable);
  };
  

  return (
    <div>
      {/* Translation Handler */}
      <TranslationHandler
        data={tableData} // Pass current table data
        onTranslationComplete={handleTranslationComplete} // Callback for translated data
      />

      <div className="table-container">
        <table className="table" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead className="table_details">
            <tr className="table-headers">
              <th>{translatedHeaders.vaccineName}</th>
              <th>{translatedHeaders.vaccinationDate}</th>
              <th>{translatedHeaders.batchNumber}</th>
              <th>{translatedHeaders.site}</th>
              <th>{translatedHeaders.adminName}</th>
            </tr>
          </thead>
          {Array.isArray(tableData) &&
            tableData.map((row, rowIndex) => (
              <tbody key={rowIndex} className="vaccine-group">
                {row.vaccinationEvents.map((event, eventIndex) => (
                  <tr key={eventIndex}>
                    {eventIndex === 0 && (
                      <td rowSpan={row.vaccinationEvents.length}>
                        <input
                          id="vaccine-name"
                          className="input-table"
                          type="text"
                          value={row.vaccineName}
                          onChange={(e) => handleChange(e, rowIndex, "vaccineName")}
                        />
                      </td>
                    )}
                    <td>
                      <input
                        className="input-table"
                        type="date"
                        value={event.date}
                        onChange={(e) => handleChange(e, rowIndex, "date", eventIndex)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-table"
                        value={event.batchNumber}
                        onChange={(e) => handleChange(e, rowIndex, "batchNumber", eventIndex)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-table"
                        value={event.site}
                        onChange={(e) => handleChange(e, rowIndex, "site", eventIndex)}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="input-table"
                        value={event.adminName}
                        onChange={(e) => handleChange(e, rowIndex, "adminName", eventIndex)}
                      />
                    </td>
                    <td>
                      <button
                        className="remove-date-button"
                        onClick={() => removeVaccinationEvent(rowIndex, eventIndex)}
                      >
                        <Remove className="table-icon" />
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="6">
                  <button
                      id="remove-vaccine"
                      className="edit-table-button"
                      onClick={() => removeVaccine(rowIndex)}
                      
                    >
                      {translatedHeaders.removeVaccine}
                    </button>

                    <button 
                      id="add-date" 
                      className="edit-table-button" 
                      onClick={() => addVaccinationEvent(rowIndex)}
                      style={{ marginLeft: "10px" }}>
                      {translatedHeaders.addAnotherDate}
                    </button>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
        <button id="add-vaccine" className="edit-table-button" onClick={addRow}>
          {translatedHeaders.addNewVaccine}
        </button>
      </div>

      <DownloadButton tableData={tableData} translatedHeaders={translatedHeaders} />

    </div>
  );
};



export default EditableTable;