import React, { useState } from "react";
import { Document, Packer, Paragraph, TextRun } from "docx";
import mammoth from "mammoth";
import { saveAs } from "file-saver";

const DownloadButton = ({ tableData, translatedHeaders }) => {
  const [selectedFormat, setSelectedFormat] = useState("");

  // 📌 Generate DOCX File
  const generateDOCX = async () => {
    if (!tableData) return null;

    const paragraphs = [new Paragraph({ text: translatedHeaders.vaccineName, heading: "Heading1" })];

    tableData.forEach((entry) => {
      paragraphs.push(new Paragraph({ text: `${translatedHeaders.vaccineName}: ${entry.vaccineName}` }));

      entry.vaccinationEvents.forEach((event) => {
        paragraphs.push(
          new Paragraph({
            children: [
              new TextRun(`${translatedHeaders.vaccinationDate}: ${event.date}, `),
              new TextRun(`${translatedHeaders.batchNumber}: ${event.batchNumber || "N/A"}, `),
              new TextRun(`${translatedHeaders.site}: ${event.site || "N/A"}, `),
              new TextRun(`${translatedHeaders.adminName}: ${event.adminName || "N/A"}`),
            ],
          })
        );
      });

      paragraphs.push(new Paragraph({})); // Space between vaccines
    });

    const doc = new Document({
      sections: [{ properties: {}, children: paragraphs }],
    });

    return new Promise((resolve) => {
      Packer.toBlob(doc).then((blob) => {
        resolve(blob);
      });
    });
  };

  // 📌 Convert DOCX to PDF
  const convertDocxToPdf = async (docxBlob) => {
    try {
      // Convert DOCX to text using Mammoth.js (preserves formatting)
      const arrayBuffer = await docxBlob.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });

      // Create a hidden iframe to print as PDF (Best workaround for preserving Chinese characters)
      const iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
        <html>
          <head><title>PDF</title></head>
          <body>${result.value}</body>
        </html>
      `);
      doc.close();

      // Use browser's built-in print-to-PDF function
      iframe.contentWindow.print();
      document.body.removeChild(iframe);
    } catch (error) {
      console.error("Error converting DOCX to PDF:", error);
    }
  };

  // 📌 Handle File Download
  const handleDownload = async (event) => {
    const format = event.target.value;
    setSelectedFormat(format);

    if (format === "docx") {
      const docxBlob = await generateDOCX();
      saveAs(docxBlob, "translated_vaccination_details.docx");
    } else if (format === "pdf") {
      const docxBlob = await generateDOCX();
      if (docxBlob) {
        convertDocxToPdf(docxBlob);
      }
    } else if (format === "smart") {
      if (!tableData) return;
      const blob = new Blob([JSON.stringify(tableData, null, 2)], { type: "application/json" });
      saveAs(blob, "translated_vaccination_details.smart");
    }
  };

  return (
    <div className="translation-container" style={{ marginTop: "10px" }}>
      <label htmlFor="download-selector" className="language-label">
        Download Data:
      </label>
      <select id="language-selector" className="language-dropdown" value={selectedFormat} onChange={handleDownload}>
        <option value="" disabled>
          Select Format
        </option>
        <option value="pdf">PDF</option>
        <option value="docx">DOCX</option>
        <option value="smart">Smart File</option>
      </select>
    </div>
  );
};

export default DownloadButton;
