import { useState, useEffect } from "react";
import { GoogleGenerativeAI } from "@google/generative-ai";
import EditableTable from "./editableTable";

function Parse({ ocrText, onDataParsed, onParseComplete }) {
  const [parsedData, setParsedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    // Fetch API key from config.json on component mount
    fetch("/config.json")
      .then(response => response.json())
      .then(config => {
        setApiKey(config.REACT_APP_GEMINI_API_KEY);
      })
      .catch(error => console.error("Error loading API key:", error));
  }, []);

  useEffect(() => {
    console.log("OCR text received:", ocrText);

    if (ocrText && apiKey) {
      fetchData(ocrText);
    }
  }, [ocrText, apiKey]); // Runs when ocrText or apiKey changes

  const cleanJSONString = (str) => {
    str = str.trim();
    if (!str.startsWith("[")) str = "[" + str;
    if (!str.endsWith("]")) str = str + "]";
    return str;
  };

  const fetchData = async (text) => {
    if (!apiKey) {
      console.error("API key not loaded yet!");
      return;
    }

    console.log("Fetching data with text:", text);
    setIsLoading(true);

    try {
      const genAI = new GoogleGenerativeAI(apiKey);
      const model = genAI.getGenerativeModel({ model: "gemini-pro" });

      const prompt = `
        Here is some OCR text containing vaccination details: "${text}". 
        Please extract the following categories: 
        - Vaccine name 
        - Vaccination date 
        - Batch/lot number 
        - Site of injection 
        - Name of the person administering the vaccine.

        If information is missing, leave it blank. Enclose property names in double quotes.

        Only return the data in a structured format like this with no other text or extra messages about what is missing: 
        [
          {
            "vaccineName": "...",
            "vaccinationEvents": [
              { "date": "MM/DD/YYYY", "batchNumber": "...", "site": "...", "adminName": "..." }
            ]
          }
        ]
      `;

      const result = await model.generateContent(prompt);
      const response = await result.response;
      const parsedText = response.text();
      const cleanedText = cleanJSONString(parsedText);
      const parsedJSON = JSON.parse(cleanedText);

      setParsedData(cleanedText);
      onDataParsed(parsedJSON);
    } catch (error) {
      console.error("Error categorizing OCR data:", error);
      setParsedData(null);
      onDataParsed(null);
    } finally {
      setIsLoading(false);
      if (onParseComplete) onParseComplete();
    }
  };

  return (
    <div className="container">
      <EditableTable ocrData={parsedData} />
    </div>
  );
}

export default Parse;
